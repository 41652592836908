import '../scss/main.scss';
//
import Ajax from "./classes/Ajax";
import VehicleCatalog from "./classes/VehicleCatalog";
import VehicleAdFilter from "./classes/VehicleAdFilter";

document.addEventListener('DOMContentLoaded', (event) => {
    Ajax.init();
    VehicleCatalog.init();
    VehicleAdFilter.init();
});
