import debounce from 'debounce';
import AjaxSnippets from "./AjaxSnippets";

export default class VehicleCatalog {
    static init() {
        VehicleCatalog.handleListFilter();
    }

    static handleListFilter() {
        $(document.body).on('input', '.js-vehicle-catalog-name', debounce(function (e) {
            e.preventDefault();
            //
            var url = $(this).attr('data-url');
            var search = $(this).val();

            // Load ajax
            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'JSON',
                data: {
                    search: search
                },
                beforeSend: function () {
                    // TODO: show animation
                },
                complete: function (xhr) {
                    // Reload content
                    AjaxSnippets.snippets(xhr.responseJSON, []);
                },
                error: function () {
                    // TODO: hide animation
                }
            });
        }, 300));
    }
}
