export default class FormHelper {
    static serializeFiltered(form) {
        var serializedArray = $(form).serializeArray();
        var filteredArray = serializedArray.filter(function (field) {
            return field.value.trim() !== '';
        });
        //
        return $.param(filteredArray);
    }
}
