import AjaxSnippets from "./AjaxSnippets";

export default class Ajax {
    static init() {
        Ajax.handleLinks();
        Ajax.handleForm();
    }

    /**
     * Redraw Ajax link
     */
    static handleLinks() {
        $(document.body).on('click', '.js-ajax-link', function (e) {
            e.preventDefault();
            //
            var $this = $(this);
            var url = $this.attr('href');
            var appendSnippets = $this.attr('data-append-snippets').split(',');

            // Run AJAX
            $.ajax({
                url: url,
                method: 'GET',
                dataType: 'JSON',
                data: {
                    //
                },
                beforeSend: function () {
                    // TODO: show animation
                },
                complete: function (xhr) {
                    // Reload content
                    AjaxSnippets.snippets(xhr.responseJSON, appendSnippets);
                },
                error: function () {
                    // TODO: hide animation
                }
            });
        })
    }

    /**
     * Handle form
     */
    static handleForm() {
        // --- On select change - load list
        $(document.body).on('change', '.js-ajax-form-select-list', function (e) {
            e.preventDefault();
            //
            var $this = $(this);
            var url = $this.attr('data-url');

            // Run AJAX
            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'JSON',
                data: {
                    value: e.target.value
                },
                beforeSend: function () {
                    // TODO: show animation
                },
                complete: function (xhr) {
                    // Reload content
                    var json = xhr.responseJSON;
                    var list = json.list;
                    var id = json.id;
                    var $select = $('#' + id);
                    //
                    $select.empty();
                    //
                    $.each(list, function (key, value) {
                        //
                        $select.append(new Option(value, key));
                        //
                        $select.removeAttr('disabled');
                    });
                },
                error: function () {
                    // TODO: hide animation
                }
            });
        })
    }
}
