import Loader from "./Loader";
import FormHelper from "./FormHelper";
import AjaxSnippets from "./AjaxSnippets";

export default class VehicleAdFilter {
    static init() {
        VehicleAdFilter.handleFilter();
        VehicleAdFilter.handleSort();
        VehicleAdFilter.handleRemoveSelectedParameter();
    }

    static handleRemoveSelectedParameter()
    {
        var $form = $('.js-vehicle-ad-filter');
        //
        $(document.body).on('click', '.js-remove-selected-parameter', function () {
            var $btn = $(this);
            var name = $btn.attr('data-name');
            // Find input
            var $input = $('*[name="'+name+'"]');
            var inputType = $input.prop('type');
            //
            console.log(inputType);
            switch (inputType) {
                case "checkbox":
                    $input.prop('checked', false);
                    break;
                case "radio":
                    $input.prop('checked', false);
                    break;
                case "select-one":
                    $input.val('');
                    break;
                case "text":
                case "number":
                case "textarea":
                    $input.val('');
                    break;
            }

            // Run filter
            VehicleAdFilter.submitFilter($form);
        });
    }

    static handleSort() {
        //
        var $form = $(this);
        //
        $(document.body).on('click', '.js-filter-sort a', function () {
            var $form = $('.js-vehicle-ad-filter');
            // Deactivate other sort
            $('.js-filter-sort a').removeClass('active');
            // Set as active
            var $sort = $(this).addClass('active');
            //
            VehicleAdFilter.submitFilter($form);
        });
    }

    static handleFilter() {
        // // On filter select change
        $(document.body).on('change', 'select', function (e) {
            var $form = $('.js-vehicle-ad-filter');
            //
            VehicleAdFilter.submitFilter($form);
        });
        $(document.body).on('change', 'input[type="checkbox"]', function (e) {
            var $form = $('.js-vehicle-ad-filter');
            //
            VehicleAdFilter.submitFilter($form);
        });
        $(document.body).on('change', 'input[type="radio"]', function (e) {
            var $form = $('.js-vehicle-ad-filter');
            //
            VehicleAdFilter.submitFilter($form);
        });
        $(document.body).on('change', 'input[type="text"]', function (e) {
            var $form = $('.js-vehicle-ad-filter');
            //
            VehicleAdFilter.submitFilter($form);
        });
        $(document.body).on('change', 'input[type="number"]', function (e) {
            var $form = $('.js-vehicle-ad-filter');
            //
            VehicleAdFilter.submitFilter($form);
        });

        // --- On submit
        $(document.body).on('submit', '.js-vehicle-ad-filter', function (e) {
            e.preventDefault();
            //
            var $form = $(this);
            //
            VehicleAdFilter.submitFilter($form);
        });
        //
    }

    static submitFilter($form) {
        //
        var url = $form.attr('data-url');
        var data = FormHelper.serializeFiltered($form[0]);
        //
        var $sort = $('.js-filter-sort a.active')
        var sort = $sort.length > 0 ? $sort.attr('data-sort') : null;

        // Run Loader
        Loader.show();

        // --- Send AJAX request
        $.ajax({
            type: 'GET',
            url: url,
            data: {
                filter: data,
                sort: sort
            },
            success: function (response) {
                // Funkcia, ktorá sa vykoná pri úspešnom odoslaní
                AjaxSnippets.snippets(response)
                // Update url
                var queryParams = new URLSearchParams(window.location.search);
                //
                queryParams.set('do', 'filter');
                queryParams.set('filter', data);
                if (sort) {
                    queryParams.set('sort', sort);
                }
                //
                history.replaceState(null, null, "?" + queryParams.toString());
                // Hide loader
                Loader.hide();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
                //
                Loader.hide();
            }
        });
    }

}
