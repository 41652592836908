export default class AjaxSnippets {
    /**
     * Redraw snippets
     *
     * @param {object} data JSON response object
     * @param {boolean} triggerAjaxSnippets
     */
    static snippets(data, appendSnippets = [], triggerAjaxSnippets = true) {
        for (var key in data.snippets) {
            if(appendSnippets.includes(key)) {
                $('#' + key).append(data.snippets[key]);
            } else {
                // Redraw
                $('#' + key).html(data.snippets[key]);
            }
        }

        // Dispatch event
        if (triggerAjaxSnippets) {
            $(document.body).trigger('ajaxSnippets');
        }
    }
}
